<!-- 
 jarvis-field 컴포넌트:
 [속성]
   label: 필드라벨
   required: 필수여부 (Boolean: true/false, String:'true'/'t'/'1' 이외에 false)
   field: 필드경로 - errors정보에서 탐색에 사용 (예: "detailsItem.xxxx")
   vErrors: 에러객체 (옵션, 기본값: $parent.errors)

   [이하는 slot전달용]
   value: Input 바인딩 값
   disabled: Input 필수여부 (Boolean: true/false, String:'true'/'t'/'1' 이외에 false)

 [사용방법 - rolemng.jsp참고]
   <jarvis-field label='라벨' :required='true' field="detailsItem.field" v-model='...' disabled=''></jarvis-field>
   <jarvis-field label='라벨' :required='true' field="detailsItem.field"><input ...></jarvis-field>
-->
<template>
	<!-- <div class="form-group"> -->
	<div class="form-group" :class="{ 'has-error': vErrors.has(field) }">
		<jarvis-field-label :required="requiredAsBoolean">{{ label }}</jarvis-field-label>
		<div class="col-lg-9">
			<slot
				:label="label"
				:required="requiredAsBoolean"
				:field="field"
				:v-errors="vErrors"
				:value="value"
				:disabled="disabledAsBoolean"
				:onInput="onInput"
				:onBlur="onBlur"
			>
				<input
					:maxLength="maxLength"
					:type="type"
					class="form-control"
					:placeholder="placeholder"
					:disabled="disabled"
					:value="value"
					:id="id"
					@input="onInput"
					@blur="onBlur"
					@keyup="onKeyUp"
				/>
			</slot>
			<jarvis-field-error :v-errors="vErrors" :field="field"></jarvis-field-error>
		</div>
	</div>
</template>

<script>
import JarvisFieldLabel from '@/components/JarvisFieldLabel';
import JarvisFieldError from '@/components/JarvisFieldError';

export default {
	components: {
		'jarvis-field-label': JarvisFieldLabel,
		'jarvis-field-error': JarvisFieldError,
	},
	model: {
		prop: 'value',
		event: 'input',
	},
	props: {
		id: { type: String, required: false },
		//최대 길이
		maxLength: { type: String, required: false },
		//필드라벨
		label: { type: String, required: false, default: () => this.field },
		//필수여부 (Boolean: true/false, String:'true'/'t'/'1' 이외에 false)
		required: { type: [String, Boolean], required: false, default: false },
		//필드경로 - errors정보에서 탐색에 사용 (예: "detailsItem.xxxx")
		// field: { type: String, required: true },
		field: { type: String, required: false },
		//에러객체 (옵션. 기본값은 $parent.vErrors 또는 $parent.errors )
		vErrors: {
			type: Object,
			required: false,
			default: function () {
				return this.$parent.vErrors || this.$parent.errors;
			},
		},

		//이하는 slot전달용////////////
		//Input 바인딩 값
		value: { type: null /*Any*/, required: false, default: null },
		//Input v-validate 규칙
		//vValidate: { type: String, required: false, default: '' },
		//Input 필수여부 (Boolean: true/false, String:'true'/'t'/'1' 이외에 false)
		disabled: { type: [String, Boolean], required: false, default: false },
		//input type (text,number,password...)
		type: { type: String, required: false, default: 'input' /*text*/ },
	},
	computed: {
		requiredAsBoolean() {
			return this.$jarvisExtention.converter.asBoolean(this.required);
		},
		disabledAsBoolean() {
			return this.$jarvisExtention.converter.asBoolean(this.disabled);
		},
		placeholder() {
			return this.disabledAsBoolean ? '' : this.label;
		},
	},
	methods: {
		onInput(event) {
			this.$emit('input', event.target.value);
		},
		// eslint-disable-next-line no-unused-vars
		onBlur(event) {
			this.$emit('blur');
		},
		onKeyUp(event) {
			this.$emit('keyup', event);
		},
	},
};
</script>
