<!--
jarvis-field-error 컴포넌트: validation 에러 라벨 표시, VeeValidate 에러정보 객체에서 자동 추출하여 표시
[속성]
  field: (String, required) 필드경로 - errors정보에서 탐색에 사용 (예: "detailsItem.xxxx")
	 v-errors: (Object) VeeValidate 에러정보 객체
[사용방법]
  <jarvis-field-error :v-errors="errors" field="detailsItem.roleName"></jarvis-field-error>
-->
<template>
	<label class="validation-error-label" v-show="vErrors.has(field)">{{ message }}</label>
</template>

<script>
export default {
	props: {
		//필드경로 - errors정보에서 탐색에 사용 (예: "detailsItem.xxxx")
		// field: { type: String, required: true },
		field: { type: String, required: false },
		//에러객체
		vErrors: {
			type: Object,
			required: false,
			default: function () {
				return this.$parent.vErrors || this.$parent.errors;
			},
		},
	},
	computed: {
		message() {
			return this.vErrors.first(this.field);
		},
	},
};
</script>
